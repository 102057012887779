// Chakra imports
import { Flex, Button, Text, useColorModeValue, FormControl, FormLabel, Input, useToast } from '@chakra-ui/react';
// Custom components
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import React, { useState } from 'react';
import { axiosPost } from 'utils/api';
import { API_ROUTES } from 'utils/constant';
import { downloadFile } from 'utils/helpers';

function Log() {
  const textColor = useColorModeValue('gray.700', 'white');
  const [path, setPath] = useState();
  const toast = useToast();

  const handleSuccess = message => {
    toast({
      title: message,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  const handleError = error => {
    toast({
      title: error,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  const clickAddButton = async () => {
    if (!path) {
      return;
    }
    try {
      const response = await axiosPost(API_ROUTES.downloadLog, {
        filePath: path,
      });
      if (response?.status === 200 || response?.status === 201) {
        downloadFile(response?.data, 'format', 'txt');
        toast({
          title: 'Tải file log thành công',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg || 'Tải file log không thành công',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleDeleteData = async () => {
    if (window.confirm('Bạn có chắc chắn muốn xoá email cũ không?')) {
      try {
        const response = await axiosPost(API_ROUTES.deleteOldData);
        if (response?.data?.code == 0) {
          handleSuccess('Xoá dữ liệu thành công');
        }
      } catch (error) {
        handleError(error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg || 'Xoá dữ liệu thất bại');
      }
    }
  };
  const handleRestart = async () => {
    if (window.confirm('Bạn có chắc chắn muốn pull và restart không?')) {
      try {
        const response = await axiosPost(API_ROUTES.pullAndRestart);
        if (response?.data?.code == 0) {
          handleSuccess('Pull và restart thành công');
        }
      } catch (error) {
        handleError(error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg || 'Pull và restart thất bại');
      }
    }
  };
  const handleLog = async () => {
    if (window.confirm('Bạn có chắc chắn muốn xem Log Forever không?')) {
      try {
        const response = await axiosPost(API_ROUTES.commonLog);
        if (response?.status == 200) {
          downloadFile(response.data, 'log_forever', 'txt');
          handleSuccess('Log Forever thành công');
        }
      } catch (error) {
        handleError(error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg || 'Log Forever thất bại');
      }
    }
  };
  

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card bg="#fff" overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Log
          </Text>
          <FormControl mt={10}>
            <FormLabel>Đường dẫn</FormLabel>
            <Input type="text" placeholder="Nhập đường dẫn" onChange={event => setPath(event.target.value)} />
          </FormControl>
          <Button variant="primary" maxH="30px" m="10px" onClick={clickAddButton}>
            Tải xuống
          </Button>
          <Button variant="primary" maxH="30px" m="10px" onClick={handleLog}>
            Log Forever
          </Button>
          <Button variant="primary" maxH="30px" m="10px" onClick={handleDeleteData}>
            Xoá dữ liệu cũ
          </Button>
          <Button variant="primary" maxH="30px" m="10px" onClick={handleRestart}>
            Pull and Restart service
          </Button>
        </CardHeader>
      </Card>
    </Flex>
  );
}

export default Log;
