import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  Portal,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import { Select } from 'chakra-react-select';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { initialFilter, API_ROUTES, SMTPGoogleStatus, SMTPLimitStatus } from 'utils/constant';
import Pagination from 'components/Pagination/Pagination';
import SMTPRow from './Components/Row';
import AddSMTPServerModal from './Components/AddSMTPSeverModal';
import { ModalType } from 'utils/constant';
import DeleteSMTPServerModal from './Components/DeleteSMTPServerModal';
import ChangeStatusModal from './Components/ChangeStatusModal';
import AddEmailBackupModal from './Components/AddEmailBackupModal';
import { downloadFile } from 'utils/helpers';
import { FileExcelValid } from 'utils/constant';
import InputSearch from 'components/InputSearch/InputSearch';
import SendEmailTestModal from './Components/SendEmailTestModal';
import ChangeLimitModal from './Components/ChangeLimitModal';
import ChangeBlockModal from './Components/ChangeBlockModal';

function SMTPServer() {
  const inputImportRef = useRef();
  const toast = useToast();
  const [filter, setFilter] = useState({ ...initialFilter, pageSize: 50 });
  const [smtpSelected, setSMTPSelected] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [settingDetail, setSettingDetail] = useState(null);
  const [error, setError] = useState();
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { isOpen: isOpenAddModal, onOpen: onOpenAddModal, onClose: onCloseAddModal } = useDisclosure();
  const { isOpen: isOpenDeleteModal, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();
  const { isOpen: isOpenChangeStatusModal, onOpen: onOpenChangeStatusModal, onClose: onCloseChangeStatusModal } = useDisclosure();
  const { isOpen: isOpenAddEmailBackupModal, onOpen: onOpenAddEmailBackupModal, onClose: onCloseAddEmailBackupModal } = useDisclosure();
  const { isOpen: isOpenSendEmailModal, onOpen: onOpenSendEmailModal, onClose: onCloseSendEmailModal } = useDisclosure();
  const { isOpen: isOpenChangeLimitModal, onOpen: onOpenChangeLimitModal, onClose: onCloseChangeLimitModal } = useDisclosure();
  const { isOpen: isOpenChangeBlockModal, onOpen: onOpenChangeBlockModal, onClose: onCloseChangeBlockModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenAddModal,
      [ModalType.Delete]: onOpenDeleteModal,
      [ModalType.ChangeStatus]: onOpenChangeStatusModal,
      [ModalType.EmailBackup]: onOpenAddEmailBackupModal,
      [ModalType.SendEmail]: onOpenSendEmailModal,
      [ModalType.ChangeLimit]: onOpenChangeLimitModal,
      [ModalType.ChangeBlock]: onOpenChangeBlockModal,
    }),
    [
      onOpenAddModal,
      onOpenDeleteModal,
      onOpenChangeStatusModal,
      onOpenAddEmailBackupModal,
      onOpenSendEmailModal,
      onOpenChangeLimitModal,
      onOpenChangeBlockModal,
    ]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseAddModal,
      [ModalType.Delete]: onCloseDeleteModal,
      [ModalType.ChangeStatus]: onCloseChangeStatusModal,
      [ModalType.EmailBackup]: onCloseAddEmailBackupModal,
      [ModalType.SendEmail]: onCloseSendEmailModal,
      [ModalType.ChangeLimit]: onCloseChangeLimitModal,
      [ModalType.ChangeBlock]: onCloseChangeBlockModal,
    }),
    [
      onCloseAddModal,
      onCloseDeleteModal,
      onCloseChangeStatusModal,
      onCloseAddEmailBackupModal,
      onCloseSendEmailModal,
      onCloseChangeLimitModal,
      onCloseChangeBlockModal,
    ]
  );

  const [{ data, loading }, refetch] = useAxios(
    {
      url: API_ROUTES.SMTPServer,
      params: filter,
    },
    {
      manual: true,
    }
  );
  const [{ loading: importLoading }, importSMTPServerApi] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.ImportSMTPServer,
    },
    { manual: true }
  );
  const [{ loading: removeAllLoading }, importDeleteMutipleApi] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.deleteSMTP,
    },
    { manual: true }
  );
  const [{ loading: activeMutipleLoading }, importActiveMutipleApi] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.ActiveMutipleSMTP,
    },
    { manual: true }
  );
  const [{ loading: limitMutipleLoading }, importLimitMutipleApi] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.LimitMutipleSMTP,
    },
    { manual: true }
  );
  const [{ loading: downloadLoading }, downloadTemplateApi] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.ExportTemplateSMTPServer,
      responseType: 'arraybuffer',
    },
    { manual: true }
  );

  useEffect(() => {
    handleRefetchData();
  }, []);

  const handelUpdateSetting = (settingDetail, modalType) => {
    setSettingDetail(settingDetail);
    openModal?.[modalType]?.();
  };

  const handelCloseAddModal = modalType => {
    closeModal?.[modalType]?.();
    setSettingDetail(null);
  };

  const handleFileSelect = e => {
    if (e?.target?.files?.[0]) {
      const smtpFile = e.target.files[0];
      const extensionFile = smtpFile?.name?.split('.')?.pop();

      if (FileExcelValid.includes(extensionFile)) {
        const formData = new FormData();
        formData.append('smtpFile', smtpFile);

        importSMTPServerApi({ data: formData })
          .then(() => {
            refetch();
            toast({
              title: 'Import khách hàng thành công',
              status: 'success',
              duration: 9000,
              isClosable: true,
            });
          })
          .catch(error => {
            toast({
              title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Import khách hàng không thành công',
              status: 'error',
              duration: 9000,
              isClosable: true,
            });
          });
      } else {
        setError('Chỉ hỗ trợ tải lại file định dạng .xlsx, ,xls');
      }
    }
  };

  const onDownloadTemplate = () => {
    setError('');
    downloadTemplateApi()
      .then(response => {
        downloadFile(response?.data, 'smtp-server-template');
        toast({
          title: 'Tải template thành công',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Tải template không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const handleRefetchData = param => {
    const params = {
      ...omit(filter, ['block', 'limit']),
      ...(filter?.block?.value && { block: filter?.block?.value }),
      ...(filter?.limit?.value && { limit: filter?.limit?.value }),
      isActive: filter?.isActive?.value,
      pageIndex: param ? param?.pageIndex : filter?.pageIndex,
    };
    refetch({
      params: { ...params },
    })
      .then(res => {})
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onChangeSearch = event => {
    event.persist();

    setFilter(prev => ({
      ...prev,
      searchKeyword: event?.target?.value,
    }));
  };

  const onClearSearch = () => {
    setFilter(prev => ({
      ...prev,
      searchKeyword: '',
    }));
  };

  const onFilter = () => {
    const params = {
      ...filter,
      pageIndex: 0,
    };
    handleRefetchData(params);
  };

  const onClearFilter = () => {
    setFilter(initialFilter);
    setTimeout(() => {
      refetch({
        params: initialFilter,
      });
    }, 0);
  };

  const handleSelectSMTP = userId => {
    const smtpSelectedTmp = [...smtpSelected];
    const userIdExists = smtpSelectedTmp.find(item => item === userId);

    if (userIdExists) {
      setSMTPSelected(smtpSelectedTmp.filter(item => item !== userId));
      return;
    }

    smtpSelectedTmp.push(userId);
    setSMTPSelected(smtpSelectedTmp);
  };

  const handleSelectAll = isSelected => {
    setIsSelectAll(!isSelected);

    if (isSelected) {
      setSMTPSelected([]);
      return;
    }

    const allSMTPSelected = data?.data.map(item => item?._id);

    setSMTPSelected(uniq([...smtpSelected, ...allSMTPSelected]));
  };

  const handleSuccess = title => {
    const params = {
      ...filter,
      pageIndex: 0,
    };
    handleRefetchData(params);
    toast({
      title: title,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  const handleDeleteAll = () => {
    if (smtpSelected.length > 0) {
      importDeleteMutipleApi({
        data: { ids: smtpSelected },
      })
        .then(res => {
          setSMTPSelected([]);
          handleSuccess('Xóa SMTP Server thành công');
        })
        .catch(error => {
          console.log('err', error);
        });
    }
  };
  const handleActiveMutiple = () => {
    if (smtpSelected.length > 0) {
      importActiveMutipleApi({
        data: { ids: smtpSelected },
      })
        .then(res => {
          setSMTPSelected([]);
          handleSuccess('Active Mutiple SMTP Server thành công');
        })
        .catch(error => {
          console.log('err', error);
        });
    }
  };
  const handleLimitutiple = () => {
    if (smtpSelected.length > 0) {
      importLimitMutipleApi({
        data: { ids: smtpSelected },
      })
        .then(res => {
          setSMTPSelected([]);
          handleSuccess('Limit Mutiple SMTP Server thành công');
        })
        .catch(error => {
          console.log('err', error);
        });
    }
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} minH="400px" pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            SMTP Server
          </Text>
          <Flex pt="22px" justifyContent="space-between">
            <Flex flex="1" alignItems="end">
              <FormControl display="flex" flexDirection={'column'} maxW="300px" mr="12px" flex="1">
                <FormLabel m="0" pr="10px">
                  Tìm kiếm:
                </FormLabel>
                <InputSearch value={filter?.searchKeyword} onChange={onChangeSearch} onClearSearch={onClearSearch} />
              </FormControl>
              <FormControl display="flex" flexDirection={'column'} maxW="150px" mr="12px" flex="1">
                <FormLabel m="0" pr="10px">
                  Google:
                </FormLabel>
                <Select
                  options={SMTPGoogleStatus}
                  value={filter?.block || null}
                  placeholder={'Chọn'}
                  chakraStyles={{
                    container: (provided, state) => ({
                      ...provided,
                      flex: 1,
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      zIndex: 10,
                    }),
                  }}
                  onChange={e => {
                    setFilter(prev => ({
                      ...prev,
                      block: e,
                    }));
                  }}
                />
              </FormControl>
              <FormControl display="flex" flexDirection={'column'} maxW="150px" mr="12px" flex="1">
                <FormLabel m="0" pr="10px">
                  Limit:
                </FormLabel>
                <Select
                  options={SMTPLimitStatus}
                  value={filter?.limit || null}
                  placeholder={'Chọn'}
                  chakraStyles={{
                    container: (provided, state) => ({
                      ...provided,
                      flex: 1,
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      zIndex: 10,
                    }),
                  }}
                  onChange={e => {
                    setFilter(prev => ({
                      ...prev,
                      limit: e,
                    }));
                  }}
                />
              </FormControl>
              <FormControl display="flex" flexDirection={'column'} maxW="150px" mr="12px" flex="1">
                <FormLabel>Trạng thái</FormLabel>
                <Select
                  options={[
                    { label: 'Active', value: true },
                    { label: 'No Active', value: false },
                  ]}
                  isClearable
                  placeholder="Chọn"
                  menuShouldBlockScroll
                  value={filter?.isActive || null}
                  chakraStyles={{
                    container: (provided, state) => ({
                      ...provided,
                      flex: 1,
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      zIndex: 10,
                    }),
                  }}
                  onChange={e => {
                    setFilter(prev => ({
                      ...prev,
                      isActive: e,
                    }));
                  }}
                />
              </FormControl>
              <Button variant="primary" maxH="30px" onClick={onFilter}>
                Lọc
              </Button>
              <Button variant="primary" maxH="30px" ml={2} px={8} onClick={onClearFilter}>
                Đặt lại
              </Button>
            </Flex>
          </Flex>
          <Flex direction="column" alignItems="flex-end">
            <Flex>
              {smtpSelected.length > 0 && (
              <Menu closeOnSelect={false}>
                <MenuButton as={Button} size="sm" variant="primary"  maxH="30px" m="10px">
                  Mutiple
                </MenuButton>
                <Portal>
                  <MenuList p={2}>
                    <Stack spacing={1}>
                      <Button variant="primary" isLoading={removeAllLoading} onClick={handleDeleteAll}>
                        Xoá nhiều
                      </Button>
                      <Button variant="primary" isLoading={activeMutipleLoading} onClick={handleActiveMutiple}>
                        Active multiple
                      </Button>
                      <Button variant="primary" isLoading={limitMutipleLoading} onClick={handleLimitutiple}>
                        Limit multiple
                      </Button>
                    </Stack>
                  </MenuList>
                </Portal>
              </Menu>
              )}

              <Button variant="primary" maxH="30px" m="10px" onClick={onOpenAddModal}>
                Thêm
              </Button>
              <Box>
                <input type="file" hidden ref={inputImportRef} onChange={handleFileSelect} />
                <Button
                  variant="primary"
                  maxH="30px"
                  m="10px"
                  isLoading={importLoading}
                  onClick={() => {
                    setError('');
                    inputImportRef?.current?.click();
                  }}
                >
                  Import
                </Button>
              </Box>
              <Button variant="primary" maxH="30px" m="10px" isLoading={downloadLoading} onClick={onDownloadTemplate}>
                Tải template
              </Button>
            </Flex>
            {!!error && <Text color={'red.300'}>{error}</Text>}
          </Flex>
        </CardHeader>
        <CardBody>
          <Stack overflow={'auto'}>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <Th borderColor={borderColor} color="gray.400">
                    <Flex flexDirection={'row'} alignItems={'center'}>
                      <Checkbox
                        id="select-all"
                        value={isSelectAll}
                        isChecked={isSelectAll}
                        onChange={() => {
                          handleSelectAll(isSelectAll);
                        }}
                      />
                    </Flex>
                  </Th>
                  <Th minWidth="130px" borderColor={borderColor} color="gray.400">
                    Brand
                  </Th>
                  {/* <Th minWidth="130px" borderColor={borderColor} color="gray.400">
                        Secure
                      </Th> */}
                  <Th minWidth="130px" borderColor={borderColor} color="gray.400">
                    User Email
                  </Th>
                  <Th minWidth="170px" borderColor={borderColor} color="gray.400">
                    User Password
                  </Th>
                  <Th minWidth="190px" borderColor={borderColor} color="gray.400">
                    Total Email Sent
                  </Th>
                  <Th minWidth="190px" borderColor={borderColor} color="gray.400">
                    Email Sent In Day
                  </Th>
                  <Th minWidth="170px" borderColor={borderColor} color="gray.400">
                    Reach the Limit
                  </Th>
                  <Th minWidth="100px" borderColor={borderColor} color="gray.400">
                    Google
                  </Th>
                  <Th minWidth="130px" borderColor={borderColor} color="gray.400">
                    Status
                  </Th>
                  <Th minWidth="130px" borderColor={borderColor} color="gray.400">
                    From Store
                  </Th>
                  <Th minWidth="170px" borderColor={borderColor} color="gray.400">
                    Latest Error Code
                  </Th>
                  <Th minWidth="170px" borderColor={borderColor} color="gray.400">
                    Reset At
                  </Th>
                  <Th minWidth="170px" borderColor={borderColor} color="gray.400">
                    Updated At
                  </Th>
                  <Th borderColor={borderColor}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.data?.map((row, index, arr) => (
                  <SMTPRow
                    key={index}
                    setting={row}
                    isLast={index === arr.length - 1 ? true : false}
                    handelUpdateSetting={handelUpdateSetting}
                    smtpSelected={smtpSelected}
                    refetch={refetch}
                    handleSelectSMTP={handleSelectSMTP}
                  />
                ))}
                {isEmpty(data?.data) && !loading && (
                  <Tr>
                    <Td colSpan="4">
                      <Box textAlign="center" height="200px" pt="24px">
                        Không có dữ liệu
                      </Box>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
            <Flex justifyContent="flex-end">
              {!isEmpty(data?.data) && (
                <Pagination
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  pageLengthMenu={[50, 100, 200, 500]}
                  onPageChange={(page, pageLength) => {
                    const params = {
                      ...omit(filter, ['block', 'limit']),
                      ...(filter?.block?.value && { block: filter?.block?.value }),
                      ...(filter?.limit?.value && { limit: filter?.limit?.value }),
                      isActive: filter?.isActive?.value,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    };

                    refetch({
                      params: params,
                    }).then(res => {
                      setFilter({
                        ...filter,
                        pageSize: pageLength,
                        pageIndex: page - 1,
                      });
                    });
                  }}
                />
              )}
            </Flex>
          </Stack>
        </CardBody>
      </Card>
      {isOpenAddModal && (
        <AddSMTPServerModal
          isOpen={isOpenAddModal}
          settingDetail={settingDetail}
          onClose={handelCloseAddModal}
          refetchData={handleRefetchData}
        />
      )}
      <DeleteSMTPServerModal
        isOpen={isOpenDeleteModal}
        settingDetail={settingDetail}
        onClose={handelCloseAddModal}
        refetchData={handleRefetchData}
      />
      <ChangeStatusModal
        isOpen={isOpenChangeStatusModal}
        settingDetail={settingDetail}
        onClose={handelCloseAddModal}
        refetchData={handleRefetchData}
      />
      {isOpenAddEmailBackupModal && (
        <AddEmailBackupModal isOpen={isOpenAddEmailBackupModal} onClose={handelCloseAddModal} refetchData={handleRefetchData} />
      )}
      {isOpenSendEmailModal && (
        <SendEmailTestModal
          isOpen={isOpenSendEmailModal}
          settingDetail={settingDetail}
          onClose={handelCloseAddModal}
          refetchData={handleRefetchData}
        />
      )}
      {isOpenChangeLimitModal && (
        <ChangeLimitModal
          isOpen={isOpenChangeLimitModal}
          settingDetail={settingDetail}
          onClose={handelCloseAddModal}
          refetchData={handleRefetchData}
        />
      )}
      {isOpenChangeBlockModal && (
        <ChangeBlockModal
          isOpen={isOpenChangeBlockModal}
          settingDetail={settingDetail}
          onClose={handelCloseAddModal}
          refetchData={handleRefetchData}
        />
      )}
    </Flex>
  );
}

export default SMTPServer;
