import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Row from './components/Row';
import CreateCampaign from './components/CreateCampaign';
import { initialFilter } from 'utils/constant';
import Pagination from 'components/Pagination/Pagination';
import { API_ROUTES } from 'utils/constant';
import { mappingCampaign } from 'utils/mapping';
import DeleteCampaignModal from './components/DeleteCampaign';
import { ModalType } from 'utils/constant';
import CampaignDetailModal from './components/CampaignDetailModal';
import InputSearch from 'components/InputSearch/InputSearch';
import { isEmpty } from 'lodash';
import { Select } from 'chakra-react-select';
import DatePicker from 'components/DatePicker/DatePicker';
import CreateEmail from './components/CreateEmail';
import { downloadFile, formatDate } from 'utils/helpers';
import moment from 'moment';

const Campaign = () => {
  const [filter, setFilter] = useState({ ...initialFilter });
  const [campaignDetail, setCampaignDetail] = useState(null);
  const [type, setType] = useState(null);
  const [filterDate, setFilterDate] = useState({
    start: null,
    end: null,
  });
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { isOpen: isRegisterOpen, onOpen: onRegisterOpen, onClose: onRegisterClose } = useDisclosure();
  const { isOpen: isOpenDetailModal, onOpen: onOpenDetailModal, onClose: onCloseDetailModal } = useDisclosure();
  // const { isOpen: isOpenEmailModal, onOpen: onOpenEmailModal, onClose: onCloseEmailModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onRegisterOpen,
      [ModalType.Detail]: onOpenDetailModal,
      // [ModalType.Email]: onOpenEmailModal,
    }),
    [onRegisterOpen, onOpenDetailModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onRegisterClose,
      [ModalType.Detail]: onCloseDetailModal,
      // [ModalType.Email]: onCloseEmailModal,
    }),
    [onRegisterClose, onCloseDetailModal]
  );

  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: API_ROUTES.Campaign,
      params: filter,
    },
    {
      manual: true,
    }
  );

  const [{ loading: exportLoading }, exportEmailApi] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.ExportEmail,
      params: filter,
      params: {
        start: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
      responseType: 'arraybuffer',
    },
    { manual: true }
  );

  useEffect(() => {
    handleRefetchData();
  }, []);

  const handelUpdateCampaign = (campaignDetail, modalType) => {
    setCampaignDetail(campaignDetail);
    openModal?.[modalType]?.();
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setCampaignDetail(null);
  };

  const handleRefetchData = () => {
    refetch({
      params: { ...filter, start: filterDate.start, end: filterDate.end},
    })
      .then(res => {})
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onChangeSearch = event => {
    event.persist();

    setFilter(prev => ({
      ...prev,
      searchKeyword: event?.target?.value,
    }));
  };

  const onClearSearch = () => {
    setFilter(prev => ({
      ...prev,
      searchKeyword: '',
    }));
  };

  const onFilter = () => {
    handleRefetchData();
  };

  const onClearFilter = () => {
    setFilter(initialFilter);
    setType(null);
    setFilterDate({
      start: '',
      end: '',
    })
    setTimeout(() => {
      refetch({
        params: initialFilter,
      });
    }, 0);
  };

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'start' && { end: new Date(formatDate(moment(date).add(2, 'months'))) }),
      [type]: date,
    }));
  };

  const onExportCustomer = () => {
    exportEmailApi()
      .then(response => {
        downloadFile(response?.data, 'customer');
        toast({
          title: 'Export thành công',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Export không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Box bg="white" mt={{ base: '120px', md: '75px' }} p="10">
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Chiến dịch
            </Text>
            <Flex alignItems={'center'} gap={'20px'} flexWrap={'wrap'}>
              <FormControl width={{ base: 'full', sm: '300px' }}>
                <FormLabel>Tìm kiếm</FormLabel>
                <Flex>
                  <InputSearch value={filter?.searchKeyword} onChange={onChangeSearch} onClearSearch={onClearSearch} />
                </Flex>
              </FormControl>
              <FormControl width={{ base: 'full', sm: '300px' }}>
                <FormLabel>Trạng thái</FormLabel>
                <Select
                  isClearable
                  placeholder="Chọn..."
                  menuShouldBlockScroll
                  value={type || null}
                  onChange={e => {
                    setType(e);
                    setFilter(prev => ({
                      ...prev,
                      isFinish: e ? e.value : null,
                    }));
                  }}
                  onMenuClose={() => {
                    if (!type) {
                      setType(null);
                    }
                  }}
                  options={[
                    { label: 'Đang gửi', value: false },
                    { label: 'Hoàn thành', value: true },
                  ]}
                ></Select>
              </FormControl>
              <FormControl width={{ base: 'full', sm: '300px' }}>
                <Flex alignItems={'center'} gap={4}>
                  <FormControl display="flex" flexDirection="column">
                    <FormLabel marginRight={0} fontSize={14} isTruncated>
                      Ngày bắt đầu
                    </FormLabel>
                    <DatePicker
                      styleInput={{
                        minWidth: '300px',
                      }}
                      selectedDate={filterDate.start}
                      onChange={date => onChangeDate('start')(date)}
                    />
                  </FormControl>
                  <FormControl display="flex" flexDirection="column">
                    <FormLabel marginRight={0} fontSize={14} isTruncated>
                      Ngày kết thúc
                    </FormLabel>
                    <DatePicker
                      styleInput={{
                        minWidth: '300px',
                      }}
                      selectedDate={filterDate.end}
                      minDate={filterDate.start}
                      maxDate={new Date(formatDate(moment(filterDate.start).add(2, 'months')))}
                      onChange={date => onChangeDate('end')(date)}
                    />
                  </FormControl>
                </Flex>
              </FormControl>
              <Button variant="primary" maxH="30px" m="10px" alignSelf={'flex-end'} onClick={onFilter}>
                Lọc
              </Button>
              <Button variant="primary" maxH="30px" m="10px" alignSelf={'flex-end'} onClick={onClearFilter}>
                Đặt lại
              </Button>
              {/* <Button  variant="primary" maxH="30px" m="10px"  alignSelf={'flex-end'} onClick={onOpenEmailModal}>
                Tạo Email
              </Button> */}
              <Button variant="primary" maxH="30px" m="10px" alignSelf={'flex-end'} isLoading={exportLoading} onClick={onExportCustomer}>
                Export
              </Button>
              <Button variant="primary" maxH="30px" m="10px" alignSelf={'flex-end'} onClick={onRegisterOpen}>
                Tạo chiến dịch
              </Button>
            </Flex>
          </CardHeader>
          <CardBody>
            <Stack overflow={'auto'}>
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400">
                    <Th pl="0px" borderColor={borderColor} color="gray.400">
                      Tên
                    </Th>
                    <Th minWidth="190px" borderColor={borderColor} color="gray.400">
                      Tổng người nhận
                    </Th>
                    <Th minWidth="250px" borderColor={borderColor} color="gray.400">
                      Tổng mail gửi thành công
                    </Th>
                    {/* <Th minWidth="180px" borderColor={borderColor} color="gray.400">
                      Tổng mail đã mở
                    </Th>
                    <Th minWidth="240px" borderColor={borderColor} color="gray.400">
                      Tổng mail gửi thất bại
                    </Th> */}
                    <Th minWidth="150px" borderColor={borderColor} color="gray.400">
                      Trạng thái
                    </Th>
                    <Th minWidth="150px" borderColor={borderColor} color="gray.400">
                      Ngày gửi
                    </Th>
                    <Th borderColor={borderColor} color="gray.400" />
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.data?.map((row, index, arr) => {
                    return (
                      <Row
                        key={row._id}
                        campaignDetail={row}
                        isLast={index === arr.length - 1 ? true : false}
                        handelUpdateCampaign={handelUpdateCampaign}
                      />
                    );
                  })}
                  {isEmpty(data?.data) && !loading && (
                    <Tr>
                      <Td colSpan="8">
                        <Box textAlign="center" height="200px" pt="24px">
                          Không có dữ liệu
                        </Box>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Stack>
            {!isEmpty(data?.data) && (
              <Flex justifyContent={'flex-end'}>
                <Pagination
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    refetch({
                      params: { ...filter, pageSize: pageLength, pageIndex: page - 1 },
                    }).then(res => {
                      setFilter({
                        ...filter,
                        pageSize: pageLength,
                        pageIndex: page - 1,
                      });
                    });
                  }}
                />
              </Flex>
            )}
          </CardBody>
        </Card>
      </Box>
      {isRegisterOpen && (
        <CreateCampaign isOpen={isRegisterOpen} onOpen={onRegisterOpen} onClose={handelCloseModal} refetchData={handleRefetchData} />
      )}
      {isOpenDetailModal && (
        <CampaignDetailModal
          isOpen={isOpenDetailModal}
          campaignDetail={campaignDetail}
          onClose={handelCloseModal}
          refetchData={handleRefetchData}
        />
      )}
      {/* {isOpenEmailModal && (
        <CreateEmail
          isOpen={isOpenEmailModal}
          onClose={handelCloseModal}
          refetchData={handleRefetchData}
        />
      )} */}
    </>
  );
};

export default Campaign;
