import React, { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import {
  Flex,
  FormLabel,
  SimpleGrid,
  FormControl,
  useColorModeValue,
  Text,
  Center,
  Button,
} from '@chakra-ui/react';
import Card from 'components/Card/Card.js';
import { checkLogin } from '../../utils/authentication';
import { useHistory } from 'react-router-dom';
import { API_ROUTES } from 'utils/constant';
import { formatDate } from 'utils/helpers';
import IconBox from 'components/Icons/IconBox';
import moment from 'moment';
import DatePicker from 'components/DatePicker/DatePicker';
import { MdEmail } from 'react-icons/md';
import CardHeader from 'components/Card/CardHeader';

export default function Dashboard() {
  const textColor = useColorModeValue('gray.700', 'white');
  const isLoggedIn = checkLogin();
  const history = useHistory();
  const [filter, setFilter] = useState({});
  const [filterDate, setFilterDate] = useState({
    start: null,
    end: null,
  });

  const [{ data: dataMonthBill, loading: billLoading }, refetch] = useAxios(
    {
      url: API_ROUTES.MonthBill,
      params: filter,
    },
    {
      useCache: false,
    }
  );

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/signin');
    }
  }, [isLoggedIn, history]);

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'start' && { end: new Date(formatDate(moment(date).add(1, 'months'))) }),
      [type]: date,
    }));
  };

  const onFilter = () => {
    setFilter(filterDate);
  };

  const onClearFilter = () => {
    setFilter({});
    setFilterDate({
      start: '',
      end: '',
    });
    setTimeout(() => {
      refetch({
        params: filter,
      });
    }, 0);
  };

  return (
    <>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px" p="10" mt={{ base: '120px', md: '75px' }}>
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Thống kê
          </Text>
          <Flex alignItems={'center'} gap={'20px'} flexWrap={'wrap'}>
            <FormControl width={{ base: 'full', sm: '300px' }}>
              <Flex alignItems={'center'} gap={4}>
                <FormControl display="flex" flexDirection="column">
                  <FormLabel marginRight={0} fontSize={14} isTruncated>
                    Ngày bắt đầu
                  </FormLabel>
                  <DatePicker
                    styleInput={{
                      minWidth: '300px',
                    }}
                    selectedDate={filterDate.start}
                    onChange={date => onChangeDate('start')(date)}
                  />
                </FormControl>
                <FormControl display="flex" flexDirection="column">
                  <FormLabel marginRight={0} fontSize={14} isTruncated>
                    Ngày kết thúc
                  </FormLabel>
                  <DatePicker
                    styleInput={{
                      minWidth: '300px',
                    }}
                    selectedDate={filterDate.end}
                    minDate={filterDate.start}
                    maxDate={new Date(formatDate(moment(filterDate.start).add(1, 'months')))}
                    onChange={date => onChangeDate('end')(date)}
                  />
                </FormControl>
              </Flex>
            </FormControl>
            <Button variant="primary" maxH="30px" m="10px" alignSelf={'flex-end'} isLoading={billLoading} onClick={onFilter}>
              Lọc
            </Button>
            <Button variant="primary" maxH="30px" m="10px" alignSelf={'flex-end'} isLoading={billLoading} onClick={onClearFilter}>
              Đặt lại
            </Button>
          </Flex>
        </CardHeader>
        <Flex flexDirection="column">
          <SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing="24px" mb="20px">
            <StatisticalData
              title="8DAY"
              value={dataMonthBill?.data?.mail8day || 0}
              icon={
                <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} mr={'16px'}>
                  <MdEmail size={'45'} />
                </IconBox>
              }
            />
            <StatisticalData
              title="888B"
              value={dataMonthBill?.data?.mail888b || 0}
              icon={
                <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} mr={'16px'}>
                  <MdEmail size={'45'} />
                </IconBox>
              }
            />
            <StatisticalData
              title="2Q"
              value={dataMonthBill?.data?.mail2q || 0}
              icon={
                <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} mr={'16px'}>
                  <MdEmail size={'45'} />
                </IconBox>
              }
            />
            <StatisticalData
              title="Đăng ký 8DAY"
              value={dataMonthBill?.data?.mailRegistration || 0}
              icon={
                <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} mr={'16px'}>
                  <MdEmail size={'45'} />
                </IconBox>
              }
            />
          </SimpleGrid>
        </Flex>
      </Card>
    </>
  );
}

const StatisticalData = props => {
  const { title, value, icon, href } = props;
  const textColor = useColorModeValue('gray.700', 'white');

  return (
    <Card minH="125px" bgColor="white" borderRadius="8px">
      <Flex justifyContent={'space-between'}>
        <Flex flexDirection={'column'}>
          <Text fontSize="5xl" color={textColor} fontWeight="bold">
            {title}
          </Text>
          <Flex direction="row" mt={'16px'} ml={'32px'}>
            <Center>
              {icon}
              <Text fontSize="5xl" color={textColor} fontWeight="900">
                {value}
              </Text>
            </Center>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
